<template>
    <div class="random-paper" v-loading="loading" element-loading-text="加载中..." element-loading-spinner="el-icon-loading">
        <HeadPath currentPath="随机组卷"></HeadPath>
        <PaperCreateSucceed v-if="isCreateSucceed"></PaperCreateSucceed>
        <div
            class="form"
            v-else-if="!createPaperParams.paper_id || (createPaperParams.paper_id && createPaperParams.category_id.length > 0)"
        >
            <el-scrollbar style="height: 100%;">
                <el-form :model="createPaperParams" :rules="rules" ref="ruleForm" label-width="100px" class="form-box">
                    <el-form-item label="试卷名称" prop="paper_name">
                        <el-input
                            type="text"
                            v-model="createPaperParams.paper_name"
                            placeholder="请输入试卷名称"
                            autocomplete="off"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="题库分类" prop="category_id">
                        <el-cascader
                            v-model="createPaperParams.category_id"
                            :options="questionLibraryList"
                            placeholder="专业大类>专业中类"
                        ></el-cascader>
                    </el-form-item>
                    <el-form-item label="抽题方式">
                        <el-radio v-model="createPaperParams.extract_questions_type" :label="1">固定题目模式</el-radio>
                        <el-radio v-model="createPaperParams.extract_questions_type" :label="2">随机题目模式</el-radio>
                    </el-form-item>
                    <el-form-item label="试卷分值">
                        <div class="total-score">
                            <el-input :placeholder="totalScore" disabled></el-input>
                            <span class="decorate">分</span>
                        </div>
                        <!-- <div class="total-score">
                            <span class="decorate">总分</span>
                            <el-input :placeholder="totalScore" disabled></el-input>
                            <span class="decorate">分</span>
                        </div> -->
                    </el-form-item>
                    <!-- <el-form-item class="type-form-item"> -->
                    <div class="row_box" style="width: 720px;">
                        <div class="title" style="display: flex; justify-content: space-between; width: 753px;">
                            <div style="display: flex; flex-direction: row;">
                                <div style="width: 100px; display: flex; align-items: center; justify-content: flex-end;">
                                    <span style="color: #FF0303;font-size: 11px; margin-top: 0px; margin-right: 4px;">*</span>
                                    <span style="font-size: 14px; color: #3E3E3E;  margin: 0 12px 0 0;">题型信息</span>
                                </div>

                                <div style="font-size: 12px; color: #999999; display: flex; align-items: center">每个试题分类至少选择一种题型</div>
                            </div>
                            <div style="font-size: 14px; display: flex; align-items: flex-end; cursor: pointer" @click="addSelectClick">
                                <div>+</div>
                                <div>新增选项</div>
                            </div>
                        </div>
                        <div class="content">
                            <div class="content_box" style="margin-bottom: 16px; position: relative" v-for="(item, index) in randomExaminationData.createRandomExaminationData" :key="index">
                                <i v-if="index !== 0" class="el-icon-delete" style="font-size: 18px; cursor: pointer;color: #FD5050; position: absolute;right: -25px; top: 10px;" @click="deleteCreateRandomExaminationData(index)"></i>
                                <div class="content_box_header">
                                    <el-row :gutter="0">
                                        <el-col :span="7">
                                            <div class="content_box_header_text">试题分类</div>
                                        </el-col>
                                        <el-col :span="3">
                                            <div class="content_box_header_text">题型</div>
                                        </el-col>
                                        <el-col :span="7">
                                            <div class="content_box_header_text">试题数量</div>
                                        </el-col>
                                        <el-col :span="7">
                                            <div class="content_box_header_text">每小题分值</div>
                                        </el-col>
                                    </el-row>
                                </div>
                                <div class="content_box_content">
                                    <el-row :gutter="0">
                                        <el-col :span="7">
                                            <div style="display: flex; align-items: center; flex-direction: column">
                                                <div :class="{border_red: item.flag}" style="width: 160px; margin-top: 10px; box-sizing: border-box">
                                                    <el-cascader @change="topicTypeSelectChange(index)" placeholder="请选择试题分类" v-model="item.type" :options="questionLibraryExistList"/>
                                                </div>
                                                <div style="width: 160px; margin-top: 16px;font-size: 14px;color: #666666">
                                                    <div>该分类下共计：</div>
                                                    <template v-if="randomExaminationData.createRandomExaminationData[index].topicNumData">
                                                        <div v-for="(item, index) in randomExaminationData.createRandomExaminationData[index].topicNumData" :key="index" style="color: #999999; margin-top: 5px">
                                                            {{ questionTypeList[item.type - 1] }}：共{{ item.num }}题
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :span="3">
                                            <div style="height: 60px; display: flex;align-items: center;justify-content: center;color: #333333; font-size: 16px" class="border_bootom" v-for="item1 in item.content" :key="item.topicLabel">{{formatTopicLabel[item1.topicLabel]}}</div>
                                        </el-col>
                                        <el-col :span="7">
                                            <div  style="height: 60px;display: flex;align-items: center;justify-content: center;" class="border_bootom" v-for="item1 in item.content" :key="item.topicLabel">
                                                <div :class="{border_red: item1.flag && item1.quantity == ''}" style="width: 160px;">
                                                    <el-input v-model="item1.quantity" placeholder="请输入内容"/>
                                                </div> <!--  @input="computedTopicScopeFn" -->
                                            </div>
                                        </el-col>
                                        <el-col :span="7">
                                            <div  style="height: 60px;display: flex;align-items: center;justify-content: center;" class="border_bootom" v-for="item1 in item.content" :key="item.topicLabel">
                                                <div :class="{border_red: item1.flag && item1.score == ''}" style="width: 160px;">
                                                    <el-input v-model="item1.score" placeholder="请输入内容"/>
                                                </div> <!--  @input="computedTopicScopeFn" -->
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="set-type-question">
                        <div class="hint">(至少选择一种题型)</div>
                        <div class="type-input-list">
                            <div class="type-input-item" v-for="(item, index) in questionTypeList" :key="index">
                                <span class="type-name"> {{ item }} </span>
                                <el-input
                                    v-model="createPaperParams.rand_paper_data[index].num"
                                    type="number"
                                    placeholder="输入试题数量"
                                ></el-input>
                                <span class="decorate">题</span>
                                <el-input
                                    v-model="createPaperParams.rand_paper_data[index].score"
                                    placeholder="输入每小题分数"
                                    type="number"
                                ></el-input>
                                <span class="decorate">分</span>
                            </div>
                        </div>
                    </div> -->
                    <!-- </el-form-item> -->
                </el-form>
            </el-scrollbar>
            <div class="footer-button">
                <el-button @click="back">返回</el-button>
                <el-button class="blue-button" type="primary" @click="submitForm('ruleForm')">提交</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import HeadPath from "components/admin/paperManage/common/HeadPath";
import PaperCreateSucceed from "components/admin/paperManage/common/PaperCreateSucceed";

import { theoryLibSelect, getRandomCategoryTopic, randomTheoryCatefory, theoryRandomPaperCreate, theoryGetRandomPaperDetail, theoryTopicList } from "@/utils/apis";
import { setTimeout } from "timers";

export default {
    name: "RandomPaper",
    data() {
        let validateScore = (rule, value, callback) => {
            let counts = value.filter((item) => item.num);
            let scores = value.filter((item) => item.score);
            this.callback = callback;
            if (counts.length <= 0) {
                callback(new Error("至少选择一种题型"));
            } else if (scores.length <= 0) {
                callback(new Error("必须设置分数"));
            } else if (scores.reduce((sum, item) => sum + item.score * item.num, 0) != 100) {
                callback(new Error("总分必须为100分"));
            } else {
                callback();
            }
        };
        return {
            loading: false,
            callback: () => {},
            isCreateSucceed: false,
            questionLibraryList: [],
            questionLibraryExistList: [],
            questionLibraryExistListCopy: [],
            questionTypeList: ["单选题", "多选题", "判断题", "填空题", "问答题"],
            createPaperParams: {
                paper_name: "",
                category_id: [],
                extract_questions_type: 1, //抽题方式  1固定  2随机
                rand_paper_data: [{}, {}, {}, {}, {}],
                paper_id: "",
            },
            rules: {
                paper_name: [
                    { required: true, message: "请输入试卷名称", trigger: "blur" },
                    { min: 3, max: 25, message: "长度必须在 3 到 25 个字符之间", trigger: "blur" },
                ],
                category_id: [{ required: true, message: "请选择题库分类", trigger: "change" }],
                rand_paper_data: [{ validator: validateScore, trigger: "blur" }],
            },
            formatTopicLabel: {
                '1': '单选题',
                '2': '多选题',
                '3': '判断题',
                '4': '填空题',
                '5': '问答题'
            },
            randomExaminationData: {
                topicName: '',
                topicType: [],
                topicScope: '',
                createRandomExaminationData: [
                    {
                        flag: false,
                        type: [],
                        content: [
                            {
                                topicLabel: '1',
                                quantity: '',
                                score: '',
                                flag: false,
                            },
                            {
                                topicLabel: '2',
                                quantity: '',
                                score: '',
                                flag: false,
                            },
                            {
                                topicLabel: '3',
                                quantity: '',
                                score: '',
                                flag: false,
                            },
                            {
                                topicLabel: '4',
                                quantity: '',
                                score: '',
                                flag: false,
                            },
                            {
                                topicLabel: '5',
                                quantity: '',
                                score: '',
                                flag: false,
                            }
                        ]
                    }
                ]
            }
        };
    },
    components: {
        HeadPath,
        PaperCreateSucceed,
    },
    async created() {
        this.loading = true;
        await this.getQuestionLibrary();
        await this.getRandomCatefory();

        let id = this.$route.query.id;
        if (id) {
            this.createPaperParams.paper_id = id;
            this.editPaper();
        }
        this.loading = false;
    },
    // async mounted() {
    // },
    computed: {
        packParams() {
            let { createPaperParams } = this;
            // let paperData = createPaperParams.rand_paper_data.map((item, index) => ({
            //     type: index + 1,
            //     num: item.num ? item.num : 0,
            //     score: item.score ? item.score : 0,
            // }));
            let paperData = this.randomExaminationData.createRandomExaminationData?.map((item, index) => {
                let result = [];
                item.content.forEach((contentItem) => { 
                    let template = {
                        category_id: item.type?.at(-1),
                    };
                    // 输入信息数据可用，并且提醒数量>0
                    if (
                        contentItem.quantity && 
                        contentItem.score && 
                        !isNaN(Number(contentItem.quantity)) && 
                        !isNaN(Number(contentItem.score)) &&
                        Number(contentItem.quantity > 0)
                    ) {
                        template.num = contentItem.quantity;
                        template.score = contentItem.score;
                        template.type = contentItem.topicLabel;

                        result.push(template);
                    }
                })
                return result;
            })
            return {
                ...createPaperParams,
                category_id: createPaperParams.category_id[1],
                rand_paper_data: paperData.flat(),
            };
        },
        totalScore() {
            // let { createPaperParams } = this;
            // let list = createPaperParams.rand_paper_data.filter((item) => item.score);
            // return list.length <= 0
            //     ? 0
            //     : list.reduce((sum, item) => {
            //           let num = !item.num ? 1 : item.num;
            //           return sum * 1 + num * item.score;
            //       }, 0);
            let sum = 0;
            this.randomExaminationData.createRandomExaminationData?.forEach((item) => {
                if (item.type?.length === 0) return;
                sum += item.content.reduce((prev, cur) => {
                    let result = prev;
                    if (cur.quantity && cur.score && !isNaN(Number(cur.quantity)) && !isNaN(Number(cur.score))) {
                        result = prev + cur.quantity * cur.score;
                    } 

                    return result;
                }, 0);
            });

            return sum;
        },
    },
    methods: {
        /**获取题库列表 */
        async getQuestionLibrary() {
            let res = await theoryLibSelect();
            if (res.code == 200) {
                this.questionLibraryList = res.data.map((item, index) => {
                    let packData = (obj) => {
                        return {
                            label: obj.name,
                            value: obj.id,
                        };
                    };
                    let temp = packData(item);
                    if (item.children.length > 0) {
                        temp.children = item.children.map(packData);
                    }
                    return temp;
                });
            } else {
                this.$message.warning(res.msg);
            }
        },
        // 获取有试题的试题分类
        async getRandomCatefory() {
            let res = await randomTheoryCatefory();
            if (res.code === 200) {
                this.questionLibraryExistList = res.data.map((item, index) => {
                    let packData = (obj) => {
                        return {
                            label: obj.name,
                            value: obj.id,
                        };
                    };
                    let temp = packData(item);
                    if (item.children.length > 0) {
                        temp.children = item.children.map(packData);
                    }
                    return temp;
                });
            }

            this.questionLibraryExistListCopy = JSON.parse(JSON.stringify(this.questionLibraryExistList));
        },
        /** 点击新增选项 */
        addSelectClick() {
            this.randomExaminationData.createRandomExaminationData.push({
                flag: false,
                type: [],
                content: [
                    {
                        topicLabel: '1',
                        quantity: '',
                        score: '',
                        flag: false,
                    },
                    {
                        topicLabel: '2',
                        quantity: '',
                        score: '',
                        flag: false,
                    },
                    {
                        topicLabel: '3',
                        quantity: '',
                        score: '',
                        flag: false,
                    },
                    {
                        topicLabel: '4',
                        quantity: '',
                        score: '',
                        flag: false,
                    },
                    {
                        topicLabel: '5',
                        quantity: '',
                        score: '',
                        flag: false,
                    }
                ]
            })
        },
        /** 删除题型信息 */
        deleteCreateRandomExaminationData(index) {
            this.randomExaminationData.createRandomExaminationData.splice(index, 1)
            // TODO 删除题型信息
            // computedTopicScopeFn()
            // this.topicTypeSelectChange();
        },
        async topicTypeSelectChange(index) {
            // console.log("val: ", this.randomExaminationData.createRandomExaminationData);
            let val = this.randomExaminationData.createRandomExaminationData[index];
            let params = {
                category_id: val?.type?.at(-1),
            }
            this.loading = true;
            let res = await getRandomCategoryTopic(params);
            if (res.code === 200) {
                let topicNumData = [
                    {
                        type: 1,
                        num: 0,
                    },
                    {
                        type: 2,
                        num: 0,
                    },
                    {
                        type: 3,
                        num: 0,
                    },
                    {
                        type: 4,
                        num: 0,
                    },
                    {
                        type: 5,
                        num: 0,
                    },
                ];
                
                topicNumData.forEach((item, index) => {
                    let resObj = res.data?.find((obj) => obj.type === item.type);

                    if (resObj) topicNumData[index].num = resObj.num;
                });

                this.$set(this.randomExaminationData.createRandomExaminationData[index], "topicNumData", topicNumData);
                val.flag = false;
                this.disabledCategoryList();
            }

            this.loading = false;
        },
        disabledCategoryList() {
            let questList = JSON.parse(JSON.stringify(this.questionLibraryExistListCopy));
            let categoryIds = this.randomExaminationData.createRandomExaminationData?.map((item) => item.type);
            // console.log(categoryIds);
            categoryIds?.forEach((ids) => {
                if (ids && ids.length > 0) {
                    let firstCategory = questList.find((item) => item.value == ids[0]);

                    if (firstCategory) {
                        let secondCategory = firstCategory?.children?.find((item) => item.value == ids.at(-1));
                        if (secondCategory) secondCategory.disabled = true;
                    }
                }
            });

            this.questionLibraryExistList = questList;
        },
        // 题型类型校验
        validateRandomData() {
            let validateChangeTypeFlag = false;
            this.randomExaminationData.createRandomExaminationData.forEach((item) => {
                if (item.type.length <= 0) {
                    this.$set(item, "flag", true);
                    validateChangeTypeFlag = true;
                } else {
                    this.$set(item, "flag", false);
                }
            });
            if (validateChangeTypeFlag) {
                this.$message.warning("请选择试题分类");
                return false;
            }

            let validateResult = true;
            for (let i = 0; i < this.randomExaminationData.createRandomExaminationData?.length; i++) {
                let item = this.randomExaminationData.createRandomExaminationData[i];
                let breakFlag = false;

                // 总分只能为100
                if (Number(this.totalScore) !== 100) {
                    validateResult = false;
                    this.$message.warning("试卷总分只能为100分");
                    break;
                }

                // 判断是否至少存在一个题型数量和分值
                let emptyFlag = item.content.some((contentItem) => {
                    return contentItem.quantity && contentItem.score && !isNaN(Number(contentItem.quantity)) && !isNaN(Number(contentItem.score));
                });
                if (!emptyFlag) {
                    validateResult = false;
                    this.$message.warning("至少填写一个题型型的数量和分值");
                    break;
                }

                for (let contentIndex = 0; contentIndex < item.content.length; contentIndex++) {
                    // 输入的不是纯数字，提示格式错误
                    let contentItem = item.content[contentIndex];

                    if (isNaN(Number(contentItem.quantity)) || isNaN(Number(contentItem.score))) {
                        breakFlag = true;
                        this.$message.warning("添加的试题数量或分值存在格式问题，请填写数字");
                        break;
                    }

                    if (contentItem.score && !isNaN(Number(contentItem.score)) && Number(contentItem.score) == 0) {
                        breakFlag = true;
                        this.$message.warning("分值必须大于或等于0.5");
                        break;
                    }

                    // 判断所选题目是否大于题库数量
                    let questionNum = item.topicNumData.find((topic) => topic.type == contentItem.topicLabel);
                    if (questionNum) {
                        if (contentItem.quantity > questionNum.num) {
                            breakFlag = true;
                            this.$message.warning(`第${i + 1}项题型信息，${this.questionTypeList[contentItem.topicLabel - 1]}数量不能大于题库数量`);
                            break;
                        }
                    }
                }

                if (breakFlag) {
                    validateResult = false;
                    break;
                }
            }

            return validateResult;
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.validateRandomData()) {
                        this.createRandomPaper();
                    };
                } else {
                    return false;
                }
            });
        },
        async createRandomPaper() {
            let res = await theoryRandomPaperCreate(this.packParams);
            if (res.code == 200) {
                this.isCreateSucceed = true;
                this.$message.success(res.msg);
            } else {
                this.$message.warning(res.msg);
            }
        },
        getCategoryIdArr(result, id, list) {
            for (let i = 0; i < list.length; i++) {
                let item = list[i];

                if (item.value === id) {
                    item.disabled = true;
                    result.unshift(item.value);

                    return true;
                } 
                
                if (item.children && item.children.length > 0) {
                    if (this.getCategoryIdArr(result, id, item.children)) {
                        result.unshift(item.value);
                        return true;
                    }
                }
            }

            return false;
        },
        async editRandomData(data) {
            let dataMap = new Map();
            let typeMap = new Map();
            data?.rand_paper_data?.forEach((item, index) => {
                if (!dataMap.has(item.category_id)) {
                    dataMap.set(item.category_id, [item]);
                } else {
                    let dataItem = dataMap.get(item.category_id);
                    dataItem?.push(item);
                    dataMap.set(item.category_id, dataItem);
                } 
            });

            if (dataMap.size !== 0) {
                let createRandomExaminationData = [];
                for (let [key, value] of dataMap) {
                    if (!typeMap.has(key)) {
                        let result = [];
                        this.getCategoryIdArr(result, key, this.questionLibraryExistList)
                        typeMap.set(key, result);
                    }

                    let params = {
                        flag: false,
                        type: typeMap.get(key),
                        content: [
                            {
                                topicLabel: '1',
                                quantity: '',
                                score: '',
                                flag: false,
                            },
                            {
                                topicLabel: '2',
                                quantity: '',
                                score: '',
                                flag: false,
                            },
                            {
                                topicLabel: '3',
                                quantity: '',
                                score: '',
                                flag: false,
                            },
                            {
                                topicLabel: '4',
                                quantity: '',
                                score: '',
                                flag: false,
                            },
                            {
                                topicLabel: '5',
                                quantity: '',
                                score: '',
                                flag: false,
                            }
                        ],
                    };
                    value?.forEach((item) => {
                        let findObj = params.content.find((find) => find.topicLabel == item.type);
                        findObj.quantity = item.num;
                        findObj.score = item.score;
                    });

                    let topicNumRes = await getRandomCategoryTopic({ category_id: key });

                    if (topicNumRes.code === 200) {
                        let topicNumData = [
                            {
                                type: 1,
                                num: 0,
                            },
                            {
                                type: 2,
                                num: 0,
                            },
                            {
                                type: 3,
                                num: 0,
                            },
                            {
                                type: 4,
                                num: 0,
                            },
                            {
                                type: 5,
                                num: 0,
                            },
                        ];
                        
                        topicNumData.forEach((item, index) => {
                            let resObj = topicNumRes.data?.find((obj) => obj.type === item.type);

                            if (resObj) topicNumData[index].num = resObj.num;
                        });
                        params.topicNumData = topicNumData;
                        createRandomExaminationData.push(params);
                    }
                }

                this.randomExaminationData.createRandomExaminationData = createRandomExaminationData;
            }
        },
        async editPaper() {
            this.loading = true;
            let { createPaperParams } = this;
            // let keys = ["single", "multiple", "judge", "blank", "answer"];
            let res = await theoryGetRandomPaperDetail({ paper_id: createPaperParams.paper_id });
            if (res.code == 200) {
                createPaperParams.paper_name = res.data.paper_name;
                createPaperParams.extract_questions_type = res.data.extract_questions_type
                this.$set(createPaperParams.category_id, 0, res.data.category_id_arr[0]);
                this.$set(createPaperParams.category_id, 1, res.data.category_id_arr[1]);
                // keys.forEach((item, index) => {
                //     let num = res.data[`${item}_num`] * 1;
                //     let score = res.data[`${item}_score`] * 1;
                //     if (num > 0) {
                //         this.$set(createPaperParams.rand_paper_data[index], "num", num);
                //     }
                //     if (score > 0) {
                //         this.$set(createPaperParams.rand_paper_data[index], "score", score);
                //     }
                // });
                this.editRandomData(res.data);

                this.loading = false;
            } else {
                this.$message.error(res.msg);
                this.loading = false;
            }
        },
        back() {
            this.$router.back();
        },
    },
};
</script>

<style scoped lang="scss">
.random-paper {
    display: flex;
    flex-direction: column;
    height: 96%;
    padding: 30px;
    color: #13131b;
    .form {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: calc(100% - 128px)
    }
    ::v-deep(.el-scrollbar__wrap) {
        overflow-x: hidden;
    }
    .form-box {
        flex: 1;
        width: 520px;
        span {
            margin: 0 10px;
            white-space: nowrap;
        }
        .set-type-question {
            .hint {
                color: #999;
            }
            ::v-deep .el-input {
                width: 180px;
            }
            .type-input-list {
                width: 555px;
                transform: translateX(-9%);
                .type-input-item:nth-child(1) {
                    margin-top: 10px;
                }
                .type-input-item {
                    display: flex;
                    margin-bottom: 20px;
                }
            }
        }
        .total-score {
            // transform: translateX(39%);
            ::v-deep .el-input {
                width: 180px;
            }
        }
        .type-form-item {
            margin-bottom: 0;
        }

        .row_box {
            .title {
                display: flex;
                align-items: center;
                overflow: hidden;
            }
            .content {
                width: 100%;
                display: flex;
                flex-direction: column;
                margin-top: 12px;
                margin-left: 33px;
                .content_box {
                    width: 100%;
                    border: 1px solid #DCDCDC;
                    border-radius: 4px;
                    .content_box_header {
                        background-color: #F2F4F7;
                        border-bottom: 1px solid #DCDCDC;
                        .content_box_header_text {
                            margin-left: 24px;
                            padding: 10px 0;
                            font-size: 16px;
                            color: #3E3E3E;
                        }
                    }
                    .content_box_content {
                        .content_box_header_text {
                            padding: 10px 0 10px 24px;
                            box-sizing: border-box;
                            font-size: 16px;
                            color: #3E3E3E;
                        }
                        .border_bootom {
                            border-bottom: 1px solid #DCDCDC;
                            border-left: 1px solid #DCDCDC;
                            :deep(.el-input__wrapper) {
                                background-color: #FFFFFF;
                                width: 100%;
                                height: 32px;
                            }
                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }

                .border_red {
                    ::v-deep(.el-input__inner) {
                        border-color: #ff0000;

                        &:hover {
                            border-color: #ff0000
                        }
                    }
                }
            }
        }
    }
    .footer-button {
        display: flex;
        justify-content: center;
        // margin-bottom: 28px;
        height: 68px;
        ::v-deep .el-button {
            width: 80px;
            height: 40px;
        }
        ::v-deep .blue-button {
            background-color: #1122d8;
            border-color: #1122d8;
        }
    }
}
//去除number input的指示器
/* chrome */
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
/* 火狐浏览器 */
::v-deep input[type="number"] {
    -moz-appearance: textfield;
}
</style>
